html, body {
  height: 100%;
  margin: 0px;
  background-color: #262626;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:#f2d6b3;
}

a {
  color: #eee;
}

.App {
  text-align: center;
}

.logo {
  height: 20vmin;
  cursor: pointer;
  margin-top: 10px;
}

.logo-homepage {
  height: 35vmin;
}

.head-table {
  width: 100%;
 
}

.head-table td:first-child, .head-table td:last-child {
  width: 33%;
 
}

.head-table td {
  text-align: center;
 
}

.nav-link {
  display: inline-block;
  color: #f2d6b3;
  border: 1px solid #f2d6b3;
  height: 30px; 
  width: 175px;
  border-radius: 10px;
  margin: 8px;
  text-align: center;
  line-height: 28px ;
  text-decoration: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.nav-link-homepage {
  margin: 17px;
  height: 35px; 
  line-height: 33px ;
  font-size: 110%;
}

.front-page-image {
  width: 100vw;
}

.nav-link:hover {
  background-color:#cb9b5e;
  color: #262626;
}

.gradient1 {
  font-family: 'Concert One', cursive;
  background-image: radial-gradient( circle 759px at -6.7% 50%, rgba(80,131,73,1) 0%, rgba(140,209,131,1) 26.2%, rgba(178,231,170,1) 50.6%, rgba(144,213,135,1) 74.1%, rgba(75,118,69,1) 100.3% );
  color: #222;
  font-size: 150%;
  font-weight: 600;
  padding: 6px;
  margin-left:3vw;
  margin-right:3vw;
  border-radius: 15px;
}

h2 {
  font-family: 'Concert One', cursive;
  background-image: linear-gradient(120deg, #f2d6b3 0%, #ebedee 100%);  color: #222;
  border-radius: 15px;
  font-size: 150%;
  font-weight: 600;
  padding: 6px;
  margin-left:3vw;
  margin-right:3vw;
}


.bios {
  padding: 50px;
  width: 100%;
}

.bios-mobile {
  padding: 10px;
  width: 100%;
}

.bios td {
  padding: 10px;
  text-align: left;
} 

.bios .bio-name {
  color: white;
  font-weight: 600;
  font-size: 120%;
  margin-right: 1em;
}

.bios-mobile .bio-name {
  display: block;
  color: white;
  font-weight: 600;
  font-size: 120%;
  margin-right: 1em;
}

.bios-mobile .bio-bio {
  display: block;
  margin-bottom: 20px;
}

.bios .bio-instr {
  font-weight: 600;
  font-size: 120%;
  margin-right: 1em;
}

.bios-mobile .bio-instr {
  display: block;
  font-weight: 600;
  font-size: 120%;
  margin-right: 1em;
}

.bios img, .bios-mobile img{
  width: 150px;
  border-radius: 50%;
}

.bios > tbody > tr:first-child > td:first-child, .bios > tbody > tr:first-child > td:last-child {
  width: 150px;
}

@media screen and (max-width: 450px) {
  .logo {
    height: 35vw;
  }
  .bios img {
    width: 150px;
    border-radius: 50%;
  }
  }

@media screen and (max-width: 639px) {

  .desktop-only1 {
    display: none;
  }
  .nav-link {
    margin: 5px;
    height: 30px; 
    width: 150px;
    margin-right:15px;
    line-height: 28px ;
  }
}

@media screen and (min-width: 640px) {

  .mobile-only1 {
    display: none;
  }

}

@media screen and (max-width: 900px) {
  .bios {
    display: none;
  }
}

@media screen and (min-width: 901px) {

  .bios-mobile {
    display: none;
  }

  .nav-link {
    height: 35px; 
    line-height: 33px ;
    font-size: 110%;
    width:200px;
  }

  .nav-link-homepage {
    margin: 25px;
  }

  .gradient1, h2 {
    font-size: 250%;
    }
}



.tour-entry {
  font-size: 130%;
}
.tour-entry > div {
  padding: 5px;
}

